<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <el-button type="primary" @click="handleClick('add')">{{
          $t("btnTip.add")
        }}</el-button>
        <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
        <template v-slot:image="slotProps">
          <div>
            <img width="100%" :src="slotProps.callback.row.image" alt />
          </div>
        </template>
        <template v-slot:active="slotProps">
          <div>
            {{ active(slotProps.callback.row.active) }}
          </div>
        </template>
        <template v-slot:operation="slotProps">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').check"
            placement="top"
          >
            <el-button
              @click="handleClick('check', slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').edit"
            placement="top"
          >
            <el-button
              @click="handleClick('edit', slotProps.callback.row)"
              icon="el-icon-edit-outline"
              :type="'primary'"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.active === 0
                ? $t('carouselManagement.active1')
                : $t('carouselManagement.active0')
            "
            placement="top"
          >
            <el-button
              @click="handleClick('active', slotProps.callback.row)"
              :type="
                slotProps.callback.row.active === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.active === 0
                  ? $t("carouselManagement.active1")
                  : $t("carouselManagement.active0")
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').delete"
            placement="top"
          >
            <el-button
              @click="del(slotProps.callback.row.id)"
              type="danger"
              size="mini"
              >{{ $t("btnTip").delete }}</el-button
            >
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { carouselList, carouselChange, carouselDelete } from "@/api/index";
export default {
  name: "carouselManagement",
  mixins: [mixin_list(carouselList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("carouselManagement.create_time"),
          sortable: true,
          prop: "create_time",
        },
        {
          label: this.$t("carouselManagement.update_time"),
          sortable: true,
          prop: "update_time",
        },
        {
          label: this.$t("carouselManagement.image"),
          slot: "image",
          prop: "image",
        },
        {
          label: this.$t("carouselManagement.order"),
          sortable: true,
          prop: "order",
        },
        {
          label: this.$t("carouselManagement.active"),
          sortable: true,
          slot: "active",
          prop: "active",
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 300,
          slot: "operation",
        },
      ],
    };
  },
  computed: {},
  methods: {
    active(active) {
      switch (active) {
        case 0:
          return this.$t("carouselManagement.active0");
        case 1:
          return this.$t("carouselManagement.active1");
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "add":
          this.$router.push(`/webpage/carousel/edit`);
          break;
        case "check":
          this.$router.push(`/webpage/carousel/detail?id=${data.id}`);
          break;
        case "active":
          await carouselChange({
            id: data.id,
            active: data.active === 0 ? 1 : 0,
          });
          this.get_list();
          break;
        case "edit":
          this.$router.push(`/webpage/carousel/edit?id=${data.id}`);
          break;
      }
    },
    async del(id) {
      this.$confirm(this.$t('carouselManagement').sureDeleteCarousel, this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await carouselDelete(id)
        this.get_list()
      }).catch({
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.span + .span::before {
  content: "/";
}
</style>